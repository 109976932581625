import { useMemo, useState } from 'react';
import { useTitle } from 'react-use';
import { match } from 'ts-pattern';

import { type DtoProgram } from '@lp-lib/api-service-client/public';

import {
  ProgramDetailsModal,
  useSearchParamProgramId,
} from '../../../app/components/Programs/Details/ProgramDetailsCard';
import { ProgramDetailsUsedBy } from '../../../app/components/Programs/Details/ProgramDetailsUsedBy';
import { HomeProgramDetailsSideAction } from '../../../app/components/PublicLibrary/HomePublicLibraryIndex';
import { PublicLibraryBreadcrumbs } from '../../../app/components/PublicLibrary/PublicLibraryBreadcrumbs';
import { useAppAnalytics } from '../../analytics/app/identifiable';
import { err2s, makeTitle } from '../../utils/common';
import { useSlackInstalledCallbackModal } from '../Channel';
import { Loading } from '../Loading';
import {
  useMyOrganization,
  useMyOrganizationFeatureChecker,
} from '../Organization/hooks/organization';
import { useOrgConnection } from '../Organization/hooks/useOrgConnection';
import { useOrgFeatureContext } from '../Organization/OrgFeatureProvider';
import { OrgSubscriptionUpgradeIcon } from '../Organization/OrgSubscription';
import { Navbar } from './Navbar';
import { ProgramCard } from './ProgramCard';
import { useActivePrograms } from './usePrograms';

export function ProgramList(): JSX.Element | null {
  useTitle(makeTitle('Programs'));
  const { org, adminMode } = useOrgFeatureContext();
  const organization = useMyOrganization();
  const analytics = useAppAnalytics();
  const programsAccess =
    useMyOrganizationFeatureChecker().hasAnyProgramAccess();
  const { connection, isValidating } = useOrgConnection({
    orgId: org.id,
    immutable: true,
  });
  const swr = useActivePrograms();
  useSlackInstalledCallbackModal();
  const [muted, setMuted] = useState(true);

  const [programId, setProgramId] = useSearchParamProgramId('programId');

  const detailsModal = useMemo(() => {
    if (!organization) return null;
    const programs = swr.data || [];
    const program = programs.find((p) => p.id === programId);
    if (!program) return null;

    return (
      <ProgramDetailsModal
        key={program.id}
        program={program}
        anonymous={false}
        relatedPrograms={programs.filter((p) => p.id !== program.id)}
        breadcrumbs={
          <PublicLibraryBreadcrumbs
            items={[
              {
                kind: 'button',
                label: 'Programs',
                onClick: () => setProgramId(null),
              },
              { kind: 'noop', label: program.name },
            ]}
          />
        }
        sideAction={
          <HomeProgramDetailsSideAction
            organization={organization}
            program={program}
          />
        }
        activelyUsedBy={
          organization ? (
            <ProgramDetailsUsedBy
              program={program}
              organization={organization}
            />
          ) : null
        }
        onClose={() => setProgramId(null)}
        onClickProgram={(program) => setProgramId(program.id)}
      />
    );
  }, [organization, programId, setProgramId, swr.data]);

  const handleClickProgram = (program: DtoProgram) => {
    analytics.trackProgramCardClicked({
      pageName: 'Programs',
      programId: program.id,
      programName: program.name,
    });
    setProgramId(program.id);
  };

  if (isValidating) return null;

  return (
    <div className='w-full flex-1 px-10 bg-game-library bg-w-full bg-no-repeat overflow-y-auto scrollbar'>
      {detailsModal}
      <Navbar
        showConnectToSlack={!adminMode && connection?.status !== 'active'}
      />
      <div className='my-10'>
        <div className='flex items-center gap-4'>
          <div className='text-white font-bold text-3.5xl'>Programs</div>
          {!programsAccess && (
            <OrgSubscriptionUpgradeIcon className='w-7.5 h-7.5 p-0.25' />
          )}
        </div>

        <div className='text-icon-gray text-xl font-medium mt-2'>
          Add Programs to your Slack channels to drive engagement and boost team
          bonding in fun ways!
        </div>
      </div>
      {match(swr)
        .when(
          () => swr.isLoading,
          () => <Loading />
        )
        .when(
          () => !!swr.error,
          () => <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
        )
        .otherwise(() => (
          <div className='flex items-center flex-wrap gap-10'>
            {swr.data?.map((p) => (
              <ProgramCard
                key={p.type}
                program={p}
                muted={muted}
                setMuted={setMuted}
                onClick={() => handleClickProgram(p)}
              />
            ))}
          </div>
        ))}
    </div>
  );
}
